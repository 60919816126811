import React, {FC, useCallback, useState} from 'react';
import {Input, InputPassword} from "../FormControls";
import mail_svg from "../../assets/images/icons/mail.svg";
import {API} from "../../utils/api";
import {ILoginRequest, IUser} from "../../utils/rest";
import {useTranslation} from "react-i18next";
import Spinner from "../Spinner";

interface Props {
  onRecovery: () => void;
  onSuccessAuth: (user: IUser) => void;
}

const FormAuth: FC<Props> = ({onRecovery, onSuccessAuth}) => {
  const {t} = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<ILoginRequest>({
    email: '',
    password: '',
  });

  const handleChange = useCallback((key: any) => (e: any) => {
    setError(false);
    setForm(prevState => {
      return {...prevState, [key]: e.target.value}
    })
  }, []);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const {token, user} = await API.Users.login(form);
      // window.location.href = `https://console.axtune.com/as/${token}`
      // localStorage.setItem('token', token);
      // API.setToken(token);
      // onSuccessAuth(user)
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [form]);

  return (
    <>
      <h2 className='modal-title mb-4'>
        <span className='text-gradient'>{t('LOGIN')}</span>
      </h2>
      <form onSubmit={handleSubmit} autoComplete="random-string">
        <Input
          error={error}
          errorText={'AUTH_ERROR'}
          className='mb-4'
          icon={mail_svg}
          type='email'
          placeholder={t('EMAIL_PLACEHOLDER') || ''}
          label='EMAIL'
          value={form.email}
          onChange={handleChange('email')}
          required
        />
        <InputPassword
          onForgotPassword={onRecovery}
          error={error}
          className='pb-4'
          value={form.password}
          onChange={handleChange('password')}
          required
        />
        <button
          type='submit'
          className='btn-gradient header-modal-btn mt-3'
          disabled={!form.email || !form.password}
        >
          {loading ? <Spinner/> : t('LOGIN')}
        </button>
      </form>
    </>
  );
}

export default FormAuth;