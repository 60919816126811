import React, {FC, useEffect, useState} from 'react';
import './header.css'
import logo from '../../assets/images/logo_color.png';
import {useTranslation} from "react-i18next";
import {Modal} from 'react-responsive-modal';
import {IUser} from "../../utils/rest";
import FormRecovery from "./FormRecovery";
import FormAuth from "./FormAuth";
import LangSelect from "../LangSelect";

interface Props {
  setUser: (user: IUser) => void;
}

const Header: FC<Props> = ({setUser}) => {
  const {t} = useTranslation();

  const [scrolling, setScrolling] = useState(false);
  const [open, setOpen] = useState(false);
  const [recoveryScreen, setRecoveryScreen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setScrolling(scrollY >= 120)
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setRecoveryScreen(false)
    setOpen(false);
  }
  return (
    <>
      <header className={scrolling ? 'scrolling' : ''}>
        <img src={logo} alt={'logo'} className='logo' />
        <div className='header-right'>
          <LangSelect/>
          <button
            type='button'
            className={`${scrolling ? 'btn-gradient' : 'btn btn-primary'} ms-4`}
            onClick={() => window.location.href = 'https://console.echotunemusic.com/login'}
          >
            <div>
              {t('LOGIN')}
            </div>
          </button>
        </div>
      </header>
      {open
        ?
        <Modal open onClose={onCloseModal} center>
          {recoveryScreen
            ?
            <FormRecovery onBack={() => setRecoveryScreen(false)}/>
            :
            <FormAuth
              onRecovery={() => setRecoveryScreen(true)}
              onSuccessAuth={setUser}
            />
          }
        </Modal>
        :
        null
      }
    </>
  );
}

export default Header;