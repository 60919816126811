import React, {FC} from 'react';
import './footer.css'
import {useTranslation} from "react-i18next";
import {POLICY, TERMS} from "../../utils/links";
import fb from '../../assets/images/fb.png';
import insta from '../../assets/images/insta.png';

interface Props {
}

const Footer: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <footer>
      <div className='d-flex align-items-center'>
        <a href={POLICY} target='_blank' className='footer-link'>{t('POLICY')}</a>
        <div>|</div>
        <a href={TERMS} target='_blank' className='footer-link'>{t('TERMS')}</a>
      </div>
      <div className='mt-2 muted-12 text-center'>
        Copyright © 2023 Echotune LLC
        Contact us at <a href={'mailto:info@echotunemusic.com'} target='_blank'>info@echotunemusic.com</a>
      </div>
      <div className="footer-social">
        <a href="https://www.instagram.com/echotunemusic" target='_blank'><img src={insta} alt="insta"/></a>
        <a href="https://www.facebook.com/echotunemusic.official/" target='_blank'><img src={fb} alt="fb"/></a>
      </div>
    </footer>
  );
}

export default Footer;
