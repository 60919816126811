import React, {FC, useCallback, useState} from 'react';
import {Input} from "../FormControls";
import mail_svg from "../../assets/images/icons/mail.svg";
import {useTranslation} from "react-i18next";
import {API} from "../../utils/api";
import Spinner from "../Spinner";

interface Props {
  onBack: () => void;
}

const FormRecovery: FC<Props> = ({onBack}) => {
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successSent, setSuccessSent] = useState(false);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await API.Users.password({email});
      setSuccessSent(true);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [email]);

  const handleChange = useCallback((e: any) => {
    setError(false);
    setSuccessSent(false);
    setEmail(e.target.value)
  }, []);

  return (
    <>
      <h2 className='modal-title mb-2'>
        <span className='text-gradient'>{t('RECOVERY_PASSWORD')}</span>
      </h2>
      <div className='muted mb-4 text-center'>{t('ENTER_EMAIL')}</div>
      <form onSubmit={handleSubmit} autoComplete="random-string">
        <Input
          errorText={'RECOVERY_ERROR'}
          error={error}
          className='mb-4'
          icon={mail_svg}
          type='email'
          placeholder={t('EMAIL_PLACEHOLDER') || ''}
          label='EMAIL'
          value={email}
          onChange={handleChange}
          required
        />

        <div className='pt-3 d-flex position-relative'>
          {successSent ? <div className={'recovery-success-text'}>{t('RECOVERY_SUCCESS')}</div> : null}
          <button
            onClick={onBack}
            type='button'
            className='btn btn-primary header-modal-btn me-2'
          >
            <div>{t('BACK')}</div>
          </button>
          <button
            type='submit'
            className='btn-gradient header-modal-btn ms-2'
            disabled={!email}
          >
            {loading ? <Spinner/> : t('NEXT')}
          </button>
        </div>
      </form>
    </>
  );
}

export default FormRecovery;