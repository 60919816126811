import React, {FC, useLayoutEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import MusicPlatformIcon from "../../components/MusicPlatformIcon";
import {EMusicPlatforms} from "../../utils/iApp";

interface Props {
  track: any;
  className?: string;
}

const TrackInfo: FC<Props> = ({track, className}) => {
  const {t} = useTranslation();
  const [tempName, setTempName] = useState(track?.name);
  const [visible, setVisible] = useState(false);
  useLayoutEffect(() => {
    setVisible(true);
    setTimeout(() => {
      setTempName(track?.name);
      setVisible(false);
    }, 1000);
  }, [track?.name]);
  return (
    <div className={`d-flex flex-column justify-content-between justify-content-lg-center ${className || ''}`}>
      <div className=''>
        <div className={`bold slider-info-track-name${visible ? ' visible' : ''}`}>
          <div className='slider-info-track-name-visible text-truncate'>{tempName}</div>
          <div className='slider-info-track-name-hidden'>{track?.name}</div>
        </div>
        <div className='bold-16 muted slider-info-track-author text-truncate'>{track?.author}</div>

      </div>
      <div>
        <div className='mt-3 muted mb-2'>{t('LISTEN')}</div>
        <div className='d-flex'>
          {track?.links ? Object.entries(track.links)?.map(([platform, link], i) => (
            <MusicPlatformIcon key={i} platform={platform as EMusicPlatforms} link={link as string} className='pointer-gradient slider-info-platform'/>
          ))
          :
          null}
          {/*<MusicPlatformIcon platform={EMusicPlatforms.Spotify} link className='pointer-gradient slider-info-platform'/>*/}
          {/*<MusicPlatformIcon platform={EMusicPlatforms.Youtube_music} link*/}
          {/*                   className='pointer-gradient slider-info-platform'/>*/}
        </div>
      </div>
    </div>
  )
}

export default TrackInfo;