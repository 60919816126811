import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../../assets/images/logo.png';
import TracksSlider from "./TracksSlider";
import {REG_LINK} from "../../utils/links";
import SectionText from "./SectionText";
import MobileTracksSider from "./MobileTracksSider";

import './section.css'
import SectionTextSlider from "./SectionTextSlider";
import slide_1 from "../../assets/images/slider/ALpha B - My Help is on the way.jpg";
import slide_2 from "../../assets/images/slider/Alpha B - Almighty God.jpg";
import slide_3 from "../../assets/images/slider/Alpha B - With You.jpg";
import slide_4 from "../../assets/images/slider/TOCCI - Kiss and tell.jpg";
import slide_5 from "../../assets/images/slider/TOCCI - No time.jpg";
import slide_6 from "../../assets/images/slider/TOCCI - 6 feet.jpg";
import slide_7 from "../../assets/images/slider/Echo boyy - Only once.jpg";
import slide_8 from "../../assets/images/slider/Echo boyy - Cash on drop.jpg";
import slide_9 from "../../assets/images/slider/RNG - Fantastick.jpg";
import slide_10 from "../../assets/images/slider/RNB - Playboy.jpg";

import {API} from "../../utils/api";
import {EMusicPlatforms} from "../../utils/iApp";

const mochaData = [
  {id: 1, img: slide_1, name: 'My Help is on the way', author: 'Alpha B', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705820177',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/6e4qRlFMF6d3ziQw3SqAnL',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/485572485',}},
  {id: 2, img: slide_2, name: 'Almighty God', author: 'Alpha B', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705774531',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/0f7XkdYYTxgO69MvQnykEk',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/485583825',}},
  {id: 3, img: slide_3, name: 'With You', author: 'Alpha B', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705774410',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/3O2PxfvBD0gQw8F82Tp0kn',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/485553035',}},
  {id: 4, img: slide_4, name: 'Kiss and tell', author: 'TOCCI', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705463176',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/1EwepFEUuWrzPRGHZ8Ev4I',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/484939765',}},
  {id: 5, img: slide_5, name: 'No time', author: 'TOCCI', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705463241',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/45biwiSQS8OhFRbFqHkD9k',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/484911455',}},
  {id: 6, img: slide_6, name: '6 feet', author: 'TOCCI', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705454172',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/4o3b1B5nZwo0uweM2ASNHR',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/484491035',}},
  {id: 7, img: slide_7, name: 'Only once', author: 'Echo boyy', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705225175',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/6dYYHxAK9imQip35LXyEjU',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/483537075',}},
  {id: 8, img: slide_8, name: 'Cash on drop', author: 'Echo boyy', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705225167',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/675mkWpm7unfebS5Tyv5iV',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/483546025',}},
  {id: 9, img: slide_9, name: 'RNB Fantastic', author: 'Lucky LB', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705463092',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/0tyaE33SqAQEydD194AZ69',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/484939695',}},
  {id: 10, img: slide_10, name: 'Playboy Curry', author: 'Lucky LB', links: {[EMusicPlatforms.Itunes]: 'https://itunes.apple.com/album/id/1705300977',[EMusicPlatforms.Spotify]: 'https://open.spotify.com/album/5zRa9IomESCeXj3vZlA9rr',[EMusicPlatforms.Deezer]: 'https://www.deezer.com/album/484510985',}},
]

interface Props {
}

const Section1: FC<Props> = () => {
  const {t} = useTranslation();
  const [isMobile, setIsMobile] = useState(true);
  const [samples, setSamples] = useState<any[]>();

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
  }, []);


  return (
    <section id={'section_1'}>
      <div className='flex-grow-1 pe-0 pe-lg-3'>
        <img src={logo} alt="logo" className='logo'/>
        {isMobile ? <SectionTextSlider/> : <SectionText/>}
        <a href={'#section_5'} className='btn-gradient'>{t('REGISTRATION')}</a>
      </div>
      {isMobile ? <MobileTracksSider tracks={mochaData}/> : <TracksSlider tracks={mochaData}/>}
    </section>
  );
}

export default Section1;